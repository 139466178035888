var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"devSupport-page"},[_c('el-breadcrumb',{staticClass:"com-breadcrumb-unit",attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ name: 'HelpDocument' }}},[_vm._v("文档")]),_c('el-breadcrumb-item',[_vm._v("开发支持")])],1),_c('el-container',{style:(_vm.navContent.length > 0
        ? 'min-height:600px;border-right:1px solid #d9d9d9;'
        : 'min-height:600px;background-color:white;')},[_c('el-aside',{staticClass:"aside-view",staticStyle:{"background-color":"white"}},[_c('div',{staticClass:"router-list"},[_c('el-menu',{attrs:{"default-openeds":_vm.opends}},[_c('el-submenu',{attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("新手指南")])]),_vm._l((_vm.items),function(item){return [(item.type == 1)?_c('router-link',{key:item.id,staticClass:"link",attrs:{"to":{
                  name: 'DevSupport',
                  params: { docId: item.id },
                  query: _vm.queryId,
                },"clstag":_vm.clstag('', item.clstag)}},[_c('el-menu-item',{attrs:{"index":'menu-' + item.id}},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2),_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("开发支持")])]),_vm._l((_vm.items),function(item){return [(item.type == 2)?_c('router-link',{key:item.id,staticClass:"link",attrs:{"to":{
                  name: 'DevSupport',
                  params: { docId: item.id },
                  query: _vm.queryId,
                },"clstag":_vm.clstag('', item.clstag)}},[_c('el-menu-item',{attrs:{"index":'menu-' + item.id}},[_vm._v(_vm._s(item.title))])],1):_vm._e()]})],2)],1)],1)]),_c('el-main',{staticClass:"main-view",staticStyle:{"background-color":"white","width":"760px"}},[(this.$route.params.docId && _vm.findItem)?_c('div',[(_vm.docContent)?_c('MarkdownPreview',{staticClass:"markdown-preview",attrs:{"after":_vm.fetchDoc},model:{value:(_vm.docContent),callback:function ($$v) {_vm.docContent=$$v},expression:"docContent"}}):_vm._e()],1):_vm._e()]),_c('aside',{staticClass:"aside-view-right"},[(_vm.navContent.length > 0)?_c('ul',{staticClass:"aside-view-right__navList"},_vm._l((_vm.navContent),function(item,index){return _c('li',{key:index,staticClass:"aside-view-right__navItem",on:{"click":function($event){return _vm.selectNavItem(index)}}},[_c('div',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"},domProps:{"innerHTML":_vm._s(item.text)}})])}),0):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }